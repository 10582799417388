/* version 1.0 */
/* eslint-disable no-shadow, consistent-return, array-callback-return, no-return-assign */
class SortFilterTemplateGloRS extends BATComponent {
	static get filterToggleButtons() {
		return [
			document.querySelector('[data-parameters="filterButton"]'),
			document.querySelector('[data-parameters="closeFilterButton"]'),
			document.querySelector('.bat-filter-overlay'),
		];
	}

	static get filterElement() {
		return document.querySelector('.bat-filter');
	}

	static get sortToggleButton() {
		return document.querySelector('[data-parameters="sortButton"]');
	}

	static get sortButtons() {
		return [...document.querySelectorAll('[data-sortprop]')];
	}

	static get sortElement() {
		return document.querySelector('.bat-sort-select');
	}

	static get collapseButtons() {
		return [...document.querySelectorAll('[data-collapse-for]')];
	}

	static get collapseIcons() {
		return [...document.querySelectorAll('[data-icon-for]')];
	}

	static get collapsItems() {
		return [...document.querySelectorAll('.bat-filter-collapse-items')];
	}

	static get clearButtons() {
		return [...document.querySelectorAll('[data-clear-for]')];
	}

	static get priceSliderElement() {
		return document.querySelector('bat-price-slider');
	}

	static get priceRangeInputs() {
		return document.querySelectorAll('[data-type="priceRangeInputs"]');
	}

	static get priceRangeNumberInputs() {
		return document.querySelectorAll(
			'[data-type="priceRangeNumberInputs" ]'
		);
	}

	static get filterCategoryCheckBoxes() {
		return [...document.querySelectorAll('[data-filter-type="category"]')];
	}

	static get filterCategoryButtons() {
		return [...document.querySelectorAll('button.category-filter-button')];
	}

	static get filterPropertyCheckBoxes() {
		return [...document.querySelectorAll('[data-filter-type="property"]')];
	}

	static get applyButton() {
		return document.querySelector('[data-parameters="applyButton"]');
	}

	static get resetButton() {
		return document.querySelector('[data-parameters="resetButton"]');
	}

	afterLoad() {
		const isAuthorMode = this.classList.contains('author-mode');

		if (isAuthorMode) {
			return false;
		}

		this.$el = $(this);

		this.sortParam = this.data.sortParm || this.data.sortConfig;
		this.categoryFilters = [];
		this.getCategoryList(this.data.rootCategory);
		this.desktop = window.matchMedia('(min-width: 1201px)');
		this.tablet = window.matchMedia(
			'(min-width: 601px) and (max-width: 1200px)'
		);
		this.phone = window.matchMedia('(max-width: 600px)');
		this.data.accordion = true;
		this.handleClickItem();
		this.setAccount();
	}

	afterUpdate() {
		this.sortToggleOpen = false;
		this.filterToggleOpen = false;
		this.propertyFilters = [];
		this.addEventListeners();
		this.handleScreenSize();
		this.setAccount();
		const categories = this.preSelectCategories(
			this.data.preSelectCategory
		);
		const filterEvent = new CustomEvent('filterPLP', {
			detail: {
				categories,
				currentPage: 1,
				sortParam: this.sortParam,
			},
		});
		document.dispatchEvent(filterEvent);
		this.handleClickItem();
		this.hideSliderMobile();
	}


	async setAccount() {
		const loggedIn = Utils.getCookie('commerce-auth-verify') === 'true';
		const isAuthorMode = this.classList.contains('author-mode');

		//fare un check anche se l'utente oltra ad essere loggato ha loyalty_optin=1
		if(this.data.rootCategory == '324' && !isAuthorMode) { 
			
			const sort_filter = this.$el.find('.filter-section');
			if(sort_filter){
				sort_filter.addClass("uk-hidden");
			}

			const customerDetails = await Commerce.getCustomerDetails();
			const plp_slot = this.$el.find('.bat-plp-slot');

			const insiders_hero = $(".insiders-hero-gloit");
			const pageHero = $('.plp-hero-gloit');


			if(!loggedIn || customerDetails.data.customer.global_loyalty_optin != '1'){
				plp_slot.addClass("uk-hidden");
				pageHero.addClass('uk-hidden');
				insiders_hero.removeClass("uk-hidden");
				//Commerce.setAuthToken("st.silvi@reply.it", "Pa55w@rd12345");
			}else{
				plp_slot.removeClass("uk-hidden");
				pageHero.removeClass('uk-hidden');
				insiders_hero.addClass("uk-hidden");
			}
			
		}
	}

	unload() {
		this.removeEventListeners();
	}

	async getCategoryList(category) {
		// Stop if no root category
		if (!category) {
			this.innerHTML = `<p class="sortfilter-category-error">ERR: Product Category Not Found<br>{ category = ${category} }</p>`;
			return false;
		}

		// const customProperties = this.data.filterConfig.groups.filter(
		// 	(item) => item.filterProp === 'property'
		// );

		// const customAttributes = customProperties
		// 	.map((property) => {
		// 		return `{attribute_code: "${property.propLabel}", entity_type: "catalog_product"}`;
		// 	})
		// 	.join('');

		// const req = await Commerce.getCategoryList(category, customAttributes);

		const req = await Commerce.getProductAggregations(category);
		const validResponse =
			req && req.data && (!req.errors || req.errors === 0);

		if (validResponse) {
			this.mapToFrontend(req.data);
		} else {
			this.innerHTML = `<p class="sortfilter-category-error">ERR: Product Category Not Found<br>{ category = ${category} }</p>`;
			return false;
		}
	}

	mapToFrontend(queryResponse) {
		const categoryList = queryResponse.products.aggregations;

		this.data = {
			...this.data,
			queryResponse,
		};

		if(this.data.filterConfig && this.data.filterConfig.groups) {
			const { groups } = this.data.filterConfig;

			groups.forEach((group) => {
				const filterGroupMatch = categoryList.find(
					(cat) => cat.attribute_code === group.propLabel
				);

				if (filterGroupMatch) {
					group.name = filterGroupMatch.label;

					if (filterGroupMatch.attribute_code === 'category_id') {
						group.items = filterGroupMatch.options.filter((option) => {
							return (
								parseInt(option.value, 10) > this.data.rootCategory
							);
						});
					} else {
						group.items = filterGroupMatch.options;
					}
				}
			});

			this.data = {
				...this.data,
				filterConfig: {
					...this.data.filterConfig,
					groups,
				},
			};
		}

		const excludedCategories = ['Shop', 'UREĐAJI', 'Simplified Devices', 'Mobile Simplified'];
		const ctaItems = [];

		if(queryResponse.products && queryResponse.products.items && queryResponse.products.items.length > 0) {
			ctaItems.push({
				ctaLabel: 'UREĐAJI',
				ctaValue: 'UREĐAJI'
			});
			const categoriesSet = new Set();
			for(const item of queryResponse.products.items) {
				if(item.categories && item.categories.length > 0) {
					for(const category of item.categories) {
						if(excludedCategories.indexOf(category.name) < 0 && !categoriesSet.has(category.name)) {
							categoriesSet.add(category.name);
							ctaItems.push({
								ctaLabel: category.name,
								ctaValue: category.name
							});
						} 
					}
				}
			}
		}

		this.data.mainNavigation = [{ctaItems}];

		this.renderComponent(this.data);
	}

	// mapToFrontend(queryResponse) {
	// 	const { categoryList } = queryResponse;
	// 	const { customAttributeMetadata } = queryResponse;

	// 	this.data = {
	// 		...this.data,
	// 		queryResponse,
	// 	};

	// 	const { groups } = this.data.filterConfig;
	// 	const groupCategory = groups.find(
	// 		(group) => group.filterProp === 'category'
	// 	);

	// 	if (groupCategory) {
	// 		const groupCategoryIndex =
	// 			(groupCategory &&
	// 				groups.findIndex(
	// 					(group) => group.filterProp === 'category'
	// 				)) ||
	// 			0;
	// 		const category = categoryList.find(
	// 			(cat) => cat.id === this.data.rootCategory
	// 		);
	// 		this.data = {
	// 			...this.data,
	// 			filterConfig: {
	// 				...this.data.filterConfig,
	// 				groups: [
	// 					...groups.slice(0, groupCategoryIndex),
	// 					{
	// 						...groupCategory,
	// 						name: category.name,
	// 						items: category.children,
	// 					},
	// 					...groups.slice(groupCategoryIndex + 1, groups.length),
	// 				],
	// 			},
	// 		};
	// 	}

	// 	const propertyFilters = this.data.filterConfig.groups.filter(
	// 		(item) => item.filterProp === 'property'
	// 	);

	// 	let products = [];

	// 	if (propertyFilters.length > 0) {
	// 		if (
	// 			categoryList.some(
	// 				(category) => category && category.children.length > 0
	// 			)
	// 		) {
	// 			products = categoryList.reduce((acc, cur) => {
	// 				return [
	// 					...acc,
	// 					...cur.children
	// 						.map((child) => child.products.items)
	// 						.reduce((acc, cur) => [...acc, ...cur], []),
	// 				];
	// 			}, []);
	// 		} else {
	// 			products = categoryList.reduce((acc, cur) => {
	// 				return [...acc, ...cur.products.items];
	// 			}, []);
	// 		}

	// 		const filters = propertyFilters.map((filter) => ({
	// 			...filter,
	// 			items: customAttributeMetadata.items.find(
	// 				(item) => filter.propValue === item.attribute_code
	// 			).attribute_options,
	// 		}));

	// 		filters.forEach((filter, index) => {
	// 			let filterGroups = this.data.filterConfig.groups;

	// 			// Filter options based on products
	// 			filter.items = filter.items.filter((item) => {
	// 				const productCount = products.reduce(
	// 					(index, product) =>
	// 						product[filter.propValue] ===
	// 						parseInt(item.value, 10)
	// 							? index + 1
	// 							: index,
	// 					0
	// 				);
	// 				item.count = productCount;
	// 				return productCount && item;
	// 			});

	// 			const groupFilterIndex = filterGroups.findIndex(
	// 				(group) => group.propValue === filter.propValue
	// 			);

	// 			filterGroups = [
	// 				...filterGroups.slice(0, groupFilterIndex),
	// 				{
	// 					...filters[index],
	// 					items: [
	// 						...filters[index].items.filter(
	// 							(filter, index, arr) =>
	// 								arr.indexOf(filter) === index
	// 						),
	// 					],
	// 				},
	// 				...filterGroups.slice(
	// 					groupFilterIndex + 1,
	// 					filterGroups.length
	// 				),
	// 			];
	// 			this.data = {
	// 				...this.data,
	// 				filterConfig: {
	// 					...this.data.filterConfig,
	// 					groups: filterGroups,
	// 				},
	// 			};
	// 		});
	// 	}
	// 	this.renderComponent(this.data);
	// }

	renderComponent(data) {
		this.setAttribute('data-model', JSON.stringify(data));
	}

	addEventListeners() {
		this.phone.addEventListener('change', this.handleScreenSize.bind(this));
		this.tablet.addEventListener(
			'change',
			this.handleScreenSize.bind(this)
		);
		this.desktop.addEventListener(
			'change',
			this.handleScreenSize.bind(this)
		);

		this.constructor.sortButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.handleSortClick.bind(this),
				false
			)
		);

		this.constructor.collapseButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.handleCollapseToggle.bind(this),
				false
			)
		);

		this.constructor.clearButtons.forEach((button) =>
			button.addEventListener(
				'click',
				this.handleClearToggle.bind(this),
				false
			)
		);

		this.constructor.priceRangeInputs.forEach((input) =>
			input.addEventListener(
				'change',
				this.handlePriceRangeChange.bind(this),
				false
			)
		);

		this.constructor.filterCategoryCheckBoxes.forEach((input) =>
			input.addEventListener(
				'change',
				this.handleCheckboxToggle.bind(this),
				false
			)
		);

		this.constructor.filterPropertyCheckBoxes.forEach((input) =>
			input.addEventListener(
				'change',
				this.handleCheckboxToggle.bind(this),
				false
			)
		);

		this.constructor.resetButton.addEventListener(
			'click',
			this.handleReset.bind(this),
			false
		);
	}

	removeEventListeners() {

		this.constructor.collapseButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.handleCollapseToggle.bind(this),
				true
			)
		);

		this.constructor.clearButtons.forEach((button) =>
			button.removeEventListener(
				'click',
				this.handleClearToggle.bind(this),
				false
			)
		);

		this.constructor.priceRangeInputs.forEach((input) =>
			input.removeEventListener(
				'change',
				this.handlePriceRangeChange.bind(this),
				false
			)
		);

		this.constructor.filterCategoryCheckBoxes.forEach((input) =>
			input.removeEventListener(
				'change',
				this.handleCheckboxToggle.bind(this),
				false
			)
		);

		this.constructor.filterPropertyCheckBoxes.forEach((input) =>
			input.addEventListener(
				'change',
				this.handleCheckboxToggle.bind(this),
				false
			)
		);

		this.constructor.resetButton.removeEventListener(
			'click',
			this.handleReset.bind(this),
			false
		);
	}

	handleApplyFilters() {
		let eventObject = {
			categories:
				this.categoryFilters.length > 0
					? this.categoryFilters
					: [this.data.rootCategory],
			currentPage: 1,
		};

		if (this.propertyFilters)
			eventObject = {
				...eventObject,
				properties: this.propertyFilters,
			};

		if (this.constructor.priceRangeNumberInputs.length > 0)
			eventObject = {
				...eventObject,
				priceRange: {
					from: this.constructor.priceRangeNumberInputs[0].value,
					to: this.constructor.priceRangeNumberInputs[1].value,
				},
			};

		if (this.sortParam)
			eventObject = {
				...eventObject,
				sortParam: this.sortParam,
			};

		const filterEvent = new CustomEvent('filterPLP', {
			detail: eventObject,
		});
		document.dispatchEvent(filterEvent);
	}

	handleReset() {
		this.constructor.filterCategoryCheckBoxes.forEach(
			(input) => (input.checked = false)
		);
		this.categoryFilters = [];
		this.constructor.filterPropertyCheckBoxes.forEach(
			(input) => (input.checked = false)
		);
		this.propertyFilters = [];
		if (
			this.data.filterConfig.groups.some(
				(filter) => filter.filterProp === 'price'
			)
		) {
			const { min } = this.data.filterConfig.groups.find(
				(filter) => filter.filterProp === 'price'
			);
			const { max } = this.data.filterConfig.groups.find(
				(filter) => filter.filterProp === 'price'
			);
			this.constructor.priceRangeInputs[0].value = min;
			this.constructor.priceRangeInputs[1].value = max;
			this.constructor.priceRangeNumberInputs[0].value = min;
			this.constructor.priceRangeNumberInputs[1].value = max;
		}
	}

	handleClearToggle(event) {
		event.stopPropagation();
		const { clearFor } = event.target.dataset;

		if (clearFor.includes('property')) {
			this.constructor.filterPropertyCheckBoxes.forEach((checkbox) => {
				const prop = clearFor.split(',');
				const propValue = prop[1];
				if (checkbox.dataset.filterProp === propValue) {
					checkbox.checked = false;
				}
				this.propertyFilters = this.propertyFilters.filter(
					(item) => item.filterProp !== propValue
				);
			});
		}

		if (clearFor === 'price') {
			const { min } = this.data.filterConfig.groups.find(
				(filter) => filter.filterProp === 'price'
			);
			const { max } = this.data.filterConfig.groups.find(
				(filter) => filter.filterProp === 'price'
			);
			if (
				this.data.filterConfig.groups.some(
					(filter) => filter.filterProp === 'price'
				)
			) {
				this.constructor.priceRangeInputs[0].value = min;
				this.constructor.priceRangeInputs[1].value = max;
				this.constructor.priceRangeNumberInputs[0].value = min;
				this.constructor.priceRangeNumberInputs[1].value = max;
			}
		}

		if (clearFor === 'category') {
			this.constructor.filterCategoryCheckBoxes.forEach((checkbox) => {
				checkbox.checked = false;
				this.categoryFilters = [];
			});
		}
	}

	handleFilterToggle(event) {
		event.stopPropagation();
		const overlay = document.querySelector('.bat-filter-overlay');
		this.filterToggleOpen
			? overlay.classList.remove('bat-filter-overlay--open')
			: overlay.classList.add('bat-filter-overlay--open');
		const element = document.querySelector('.bat-filter');
		const left = this.filterToggleOpen ? '-120vw' : '0px';
		element.style.left = left;
		element.style.display = 'flex';
		this.filterToggleOpen = !this.filterToggleOpen;
	}

	handleSortToggle(event) {
		event.stopPropagation();
		const buttonHeight = event.target.closest('button').offsetHeight;
		const buttonWidth = event.target.closest('button').offsetWidth;
		this.constructor.sortElement.style.display = 'none';
		const display = this.sortToggleOpen ? 'none' : 'block';
		this.sortToggleOpen = !this.sortToggleOpen;
		this.constructor.sortElement.style.display = display;
		this.constructor.sortElement.style.width = `${buttonWidth}px`;
		this.constructor.sortElement.style.transform = `translateY(${buttonHeight}px)`;

		if (this.sortToggleOpen) {
			event.currentTarget.classList.add('open');
		} else {
			event.currentTarget.classList.remove('open');
		}
	}

	handleSortClick(event) {
		event.stopPropagation();
		this.sortToggleOpen = !this.sortToggleOpen;
		this.constructor.sortElement.style.display = 'none';
		this.sortParam = this.data.sortConfig.params.find(
			(param) =>
				param.sortProp === event.target.dataset.sortprop &&
				param.sortDir === event.target.dataset.sortdir
		);

		const filterEvent = new CustomEvent('filterPLP', {
			detail: {
				currentPage: 1,
				sortParam: this.sortParam,
			},
		});

		this.constructor.sortToggleButton.classList.remove('open');
		document.dispatchEvent(filterEvent);
	}

	handleCollapseToggle(event) {
		event.stopPropagation();
		const { collapseFor } = event.currentTarget.dataset;
		const itemsElement = document.querySelector(
			`[data-items-for="${collapseFor}"]`
		);
		const itemsElementVisible =
			itemsElement.display === '' ||
			itemsElement.style.display === 'block';
		itemsElement.style.display = itemsElementVisible ? 'none' : 'block';
		const toggleIconElement = document.querySelector(
			`[data-icon-for="${collapseFor}"]`
		);
		const toggleClassList = itemsElementVisible
			? `${this.data.collapseDownIcon}`
			: `${this.data.collapseUpIcon}`;
		toggleIconElement.classList = toggleClassList;
	}

	handlePriceRangeChange() {
		this.constructor.priceRangeInputs.forEach(() => {
			let slide1 = parseFloat(this.constructor.priceRangeInputs[0].value);
			let slide2 = parseFloat(this.constructor.priceRangeInputs[1].value);
			if (slide1 > slide2) {
				[slide1, slide2] = [slide2, slide1];
			}
			this.constructor.priceRangeNumberInputs[0].value = slide1;
			this.constructor.priceRangeNumberInputs[1].value = slide2;
		});
	}

	handleCheckboxToggle(event) {
		event.stopPropagation();
		const { checked } = event.target;
		const { filterType } = event.target.dataset;
		const { filterValue } = event.target.dataset;
		const { filterProp } = event.target.dataset;
		switch (filterType) {
			case 'property':
				this.propertyFilters = checked
					? [
							...this.propertyFilters.filter(
								(filter) => filter.filterValue !== filterValue
							),
							{
								filterProp,
								filterValue,
							},
					  ]
					: this.propertyFilters.filter(
							(filter) => filter.filterValue !== filterValue
					  );
				break;
			default:
				this.categoryFilters = checked
					? [
							...this.categoryFilters.filter(
								(category) => category !== +filterValue
							),
							+filterValue,
					  ]
					: this.categoryFilters.filter(
							(category) => category !== +filterValue
					  );
				break;
		}
	}

	handleScreenSize() {
		if (this.desktop.matches) {
			this.constructor.filterElement.style.left = this.filterToggleOpen
				? 0
				: '-100vw';
			this.constructor.filterElement.style.position = 'fixed';
			this.constructor.filterElement.style.top = 0;
			this.constructor.filterElement.style.bottom = '0px';
			this.constructor.filterElement.style.height = 'auto';
			this.constructor.filterElement.style.width = '32vw';
			// this.constructor.filterElement.style.display = 'block';
			// this.constructor.filterElement.style.left = 'initial';
			// this.constructor.filterElement.style.position = 'initial';
			// this.constructor.filterElement.style.top = 'initial';
			// this.constructor.filterElement.style.width = '500px';
			// this.constructor.filterElement.style.zIndex = 'initial';

			// this.constructor.filterToggleButtons.forEach((button) => {
			// 	button.style.display = 'none';
			// });
			this.constructor.collapseIcons.forEach((icon) => {
				icon.classList = `${this.data.collapseUpIcon}`;
			});
			this.constructor.collapsItems.forEach((item) => {
				item.style.display = 'block';
			});
			this.constructor.collapsItems.forEach((item) => {
				item.style.display = 'block';
			});
		} else {
			this.constructor.filterElement.style.left = this.filterToggleOpen
				? '0px'
				: '-120vw';
			this.constructor.filterElement.style.position = 'fixed';
			this.constructor.filterElement.style.top = 0;
			this.constructor.filterElement.style.bottom = '0px';
			this.constructor.filterElement.style.height = 'auto';
			this.constructor.collapseIcons.forEach((icon) => {
				icon.classList = `${this.data.collapseDownIcon}`;
			});
			this.constructor.collapsItems.forEach((item) => {
				item.style.display = 'none';
			});
			// this.constructor.filterToggleButtons[0].parentElement.style.width =
			// 	'50%';
			// this.constructor.sortToggleButton.parentElement.style.width = '50%';
		}
		if (this.tablet.matches) {
			this.constructor.filterElement.style.width = '50vw';
		}
		if (this.phone.matches) {
			this.constructor.filterElement.style.width = '90vw';
		}
	}

	preSelectCategories(preSelectCategory) {
		this.constructor.filterCategoryCheckBoxes.forEach((element) => {
			if (+element.name === preSelectCategory) {
				element.checked = true;
			}
		});
		this.categoryFilters = preSelectCategory ? [preSelectCategory] : [];
		return preSelectCategory
			? [preSelectCategory]
			: [this.data.rootCategory];
	}

	handleClickItem() {
		let listItems = document.querySelectorAll('.slider-item');
		listItems.forEach((item) => {
			item.addEventListener('click', () => {
				if(item.classList.contains('selected')) {
					item.classList.remove('selected');
				} else {
					item.classList.add('selected');
				}
				this.handleClickCategory();
			})
		});

	}

	handleClickCategory() {
		const categoryButtons = this.constructor.filterCategoryButtons;
		const customCategories = [];
		if(categoryButtons.length > 0) {
			for(const button of categoryButtons) {
				if(button) {
					const sliderItem = button.closest('.slider-item');
					if(sliderItem && sliderItem.classList.contains('selected')) {
						const catId = button.dataset.value;
						if(catId) {
							customCategories.push(catId);
						}						
					}
				}
			}
		}
		const filterEvent = new CustomEvent('filterPLP', {
			detail: {
				currentPage: 1,
				customCategories
			}
		});

		document.dispatchEvent(filterEvent);
	}

	hideSliderMobile(){
		let categorySlider = document.querySelector('.category-slider');
		if(window.innerWidth <= 640){
			if(this.data.accordion){
				console.log(this.data.accordion);
				categorySlider.classList.add('hidden');
			}
		}
		else{
			categorySlider.classList.remove('hidden');
		}
	}
}

!customElements.get('bat-sortfilter-glors') &&
	customElements.define('bat-sortfilter-glors', SortFilterTemplateGloRS);
